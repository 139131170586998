// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-live-performances-js": () => import("./../src/pages/live-performances.js" /* webpackChunkName: "component---src-pages-live-performances-js" */),
  "component---src-pages-music-js": () => import("./../src/pages/music.js" /* webpackChunkName: "component---src-pages-music-js" */),
  "component---src-pages-painting-js": () => import("./../src/pages/painting.js" /* webpackChunkName: "component---src-pages-painting-js" */),
  "component---src-pages-photographs-js": () => import("./../src/pages/photographs.js" /* webpackChunkName: "component---src-pages-photographs-js" */),
  "component---src-pages-showreel-js": () => import("./../src/pages/showreel.js" /* webpackChunkName: "component---src-pages-showreel-js" */),
  "component---src-pages-video-performances-js": () => import("./../src/pages/video-performances.js" /* webpackChunkName: "component---src-pages-video-performances-js" */),
  "component---src-pages-writing-js": () => import("./../src/pages/writing.js" /* webpackChunkName: "component---src-pages-writing-js" */),
  "component---src-templates-about-page-js": () => import("./../src/templates/about-page.js" /* webpackChunkName: "component---src-templates-about-page-js" */),
  "component---src-templates-work-page-js": () => import("./../src/templates/work-page.js" /* webpackChunkName: "component---src-templates-work-page-js" */)
}

